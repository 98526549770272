<template>
  <div class="page page-projects">
    <Nav />
    <div class="container heading">
      <div class="row">
        <div class="col">
          <h1>Projects</h1>
          <hr />
        </div>
      </div>
    </div>
    <div class="projects single" v-if="project">
    <div class="container">
      <div class="row">
        <div class="col">
          <h3>{{ project.title }}</h3>
          <hr />
          <div v-html="project.content"></div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'

const axios = require('axios')

export default {
  name: 'Project',
  components: {
    Nav
  },
  data: function () {
    return {
      project: null
    }
  },
  methods: {
    setProject (project) {
      if (project !== undefined && project.id !== undefined) {
        this.project = project
      } else {
        this.project = null
      }
    }
  },
  beforeMount () {
    axios
      .get(this.$store.state.endpoint + 'project?id=' + this.$route.params.project_id)
      .then((response) => {
        try {
          response = response.data
          if (response.status !== 'success') {
            throw response.error.length > 0 ? response.error[0] : 'Unknown Error'
          }
          this.setProject(response.data)
        } catch (e) {
          console.error(e)
          this.$router.push({ name: 'Home' })
        }
      })
      .catch(function () {
        this.$router.push({ name: 'Home' })
      })
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/projectshead.scss';
@import url(https://cms.basicl.aw/wp-content/plugins/elementor/assets/css/frontend-lite.min.css?ver=3.6.6);

h1 {
  margin-bottom: 10px;
  text-align: left;
  color: $key-yellow;
  font-size: 2.8em;
  font-weight: 700;
}

</style>
