<template>
  <div class="nav">
    <div class="left">
      <router-link
        :to="{ name: 'Home', params: { locale: $i18n.locale } }"
        class="btn"
        :class="{active:$route.name === 'Home'}">
        <span class="d-none d-sm-inline">Home</span>
        <span class="d-inline d-sm-none"><i class="fa-solid fa-house-chimney"></i></span>
      </router-link>
      <router-link
        :to="{ name: 'Projects', params: { locale: $i18n.locale } }"
        class="btn btn-green"
        :class="{active:$route.name === 'Projects'}">
        <span class="d-none d-sm-inline">Projects</span>
        <span class="d-inline d-sm-none"><i class="fa-solid fa-folder-open"></i></span>
      </router-link>
      <router-link
        :to="{ name: 'ToolsHome', params: { locale: $i18n.locale } }"
        class="btn btn-green"
        :class="{active:$route.name === 'ToolsHome'}">
        <span class="d-none d-sm-inline">Toolbox</span>
        <span class="d-inline d-sm-none"><i class="fa-solid fa-wrench"></i></span>
      </router-link>
    </div>
    <div class="right">
      <a href="mailto:basiclaw@it9.hk" class="btn">
        <span class="d-none d-sm-inline"><i class="fa-solid fa-envelope"></i> Contact Me</span>
        <span class="d-inline d-sm-none"><i class="fa-solid fa-envelope"></i></span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav'
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.nav {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: $dark-grey;
  color: $beige;
  z-index: 999999;

  > div {
    display: flex;
    justify-content: center;
  }
}
.btn {
  display: inline-block;
  margin: 10px;
  padding: 5px 18px;
  border: 2px dashed;
  border-radius: 5px;
  background-color: $btn-background;
  box-shadow: 8px 8px 0 $btn-shadow;
  line-height: 1em;
  font-size: 0.8em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  color: $key-yellow;

  &.btn-green {
    color: $key-green;
  }
  &:hover, &.active {
    border-style: solid;
  }
}
</style>
